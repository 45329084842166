/* expanded box styling  */

.toggler {
  height: 290px !important;
}
.toggler .add-wallet {
  bottom: 10px !important;
}

/* tabs height for mobile & tablets  */
.tabs-height {
  height: calc(100vh - 265px);
}

/*using media query for tabs height for mobile  */
@media (min-width: 1024px) {
  .tabs-height {
    height: calc(100vh - 251px);
  }
}

/* range slider on donation box start  */
.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rangeSlider:hover {
  opacity: 1;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffcab0;
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffcab0;
  cursor: pointer;
}
