.img-container {
  width: 640px;
  height: 480px;
  float: left;
}

.img-preview {
  width: 200px;
  height: 200px;
  float: left;
  margin-left: 10px;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

/* .cropper-view-box {
  box-shadow: none;
  outline: 0;
} */
