:root {
  --green: #44cd51;
  --bg-btn: #ffcab0;
  --btn-inlay-primary: #000;
  --bg-body: #fff;
  --bg-tile: #1D1D1D;
  --bg-tile-form: #1D1D1D;
  --bg-logo: #ffcab0;
  --tile-inlay-primary: #fff;
  --body-inlay-primary: #fff;
  --landing-page-inlay: #fff
}

body {
  background-color: var(--bg-body) !important;
}
.bg-tile {
  background-color: var(--bg-tile);
}
.bg-tile-form {
  background-color: var(--bg-tile-form);
}
.bg-logo {
  fill: var(--bg-logo);
}
.ring-color {
  color: var(--bg-btn);
}
.bg-logo-color {
  color: var(--bg-logo);
}
.copy-icon-color {
  color: var(--bg-btn) !important;
}
.bg-tile {
  background-color: var(--bg-tile) !important;
}
.bg-body {
  background-color: var(--bg-body) !important;
}
.tile-inlay-primary {
  color: var(--tile-inlay-primary) !important;
}
.body-inlay-primary {
  color: var(--body-inlay-primary) !important;
}
.bg-btn {
  background-color: var(--bg-btn) !important;
}
.btn-inlay-primary {
  color: var(--btn-inlay-primary) !important;
}
.green {
  color: var(--green);
}
.bg-green {
  background-color: var(--green);
}
.landing-page-inlay {
  color: var(--landing-page-inlay) !important;
}

/* leaderboard  */
.blue-gradient{
  background: linear-gradient(99.83deg, #0373F3 7.14%, #3290FD 22.38%, #59A6FF 36.16%, #3290FD 61.18%, #0373F3 85.14%)
}

.golden-gradient{
  background: linear-gradient(99.83deg, #B59859 7.14%, #EED090 22.38%, #EEDAB0 36.16%, #D0B06A 61.18%, #B59859 85.14%);
}

.silver-gradient{
  background: linear-gradient(120.03deg, #CFCFCF 25.79%, #E4E4E4 34.97%, #E6E6E6 40.82%, #DFDFDF 47.78%, #C5C5C5 63.08%)
}