* {
  font-size: 15px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -msbox-sizing: border-box;
}


html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  background-color: #000;
}

#root {
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;
}

/* global css start  */
.grow-2 {
  flex-grow: 2;
}
.container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.border-menu-0 .ui.dropdown .menu {
  border: none !important;
}
.rounded-b-4xl {
  border-bottom-right-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
}
.shadow-black {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.9);
}
/* global css end  */

/* scroller start  */
#scrollbar::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}
#scrollbar::-webkit-scrollbar-track {
  background: 0 0;
  border-radius: 30px;
  width: 7px;
}
#scrollbar::-webkit-scrollbar-thumb {
  border-radius: 30px;
  width: 7px;
  background: rgba(255, 255, 255, 0.3);
}
#scrollbar::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  width: 7px;
  opacity: 0.4;
}
/* scroller end  */

/* creator scroller start  */
#darkscrollbar::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}
#darkscrollbar::-webkit-scrollbar-track {
  background: 0 0;
  border-radius: 30px;
  width: 7px;
}
#darkscrollbar::-webkit-scrollbar-thumb {
  border-radius: 30px;
  width: 7px;
  background: rgba(0, 0, 0, 0.2);
}
#darkscrollbar::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  width: 7px;
  opacity: 0.4;
}
/* creator scroller end  */

/* hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* media queries  */
@media (min-width: 1024px) and (max-width: 1500) {
  html[style="overflow: hidden;"] {
    padding-right: 7px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .h-278 {
    height: 278px;
  }
}

/* native select dropdowns positions start  */

select.appearance-native {
  /** for the dropdown indicator */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 20px;
}

/* toast container  start full width start*/
.Toastify__toast-container--bottom-center {
  max-width: 1500px;
  width: 95% !important;
}

@media only screen and (max-width: 480px){
  .Toastify__toast-container--bottom-center{
    bottom: 10px !important;
    left: 9px !important;
  }
}
/* toast container  start full width end*/

@keyframes slideIn {
  from {
    top: -100px;
  }
  to {
    top: -1px;
  }
}

.slide-in {
  animation: slideIn 0.3s ease-in-out;
}
.sentence-case {
  text-transform: lowercase;
}
.sentence-case:first-letter {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .team-germany-abudhabi {
    background-image: url("./assets/images/desktop-bg/team-germany.png");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 768px) {
  .team-germany-abudhabi {
    background-image: url("./assets/images/mobile-bg/team-germany.png");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .magic-germany-etw {
    background-image: url("./assets/images/desktop-bg/magic-germany.jpg");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 768px) {
  .magic-germany-etw {
    background-image: url("./assets/images/mobile-bg/magic-germany.jpg");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px var(--bg-tile-form) inset !important;
}