.signedout-tab {
  height: calc(100vh - 405px);
}

/*using media query for tabs height for mobile  */
@media (min-width: 1024px) {
  .signedout-tab {
    height: calc(100vh - 100px);
  }
}
