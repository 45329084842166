/* #scrollbar{
  overflow-y: auto;
} */

.landing {
  background-image: url("../../assets/images/creator_background.webp");
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 992px) {
  .landing {
    background-size: 100% 100%;
  }
}

@media (min-width: 1024px) {
  .transition-linear-out {
    transition: visibility 0s linear 200ms, opacity 200ms;
  }
  .transition-linear-in {
    transition: visibility 0s linear 0s, opacity 200ms;
  }
}

.scroll-animation div:first-child {
  animation: show 15000ms;
  animation-direction: reverse;
  transition-property: all;
  animation-fill-mode: forwards;
}

@keyframes show {
  0% {
    margin-top: -558px;
  }
  7% {
    margin-top: -558px;
  }
  13% {
    margin-top: -558px;
  }
  20% {
    margin-top: -465px;
  }
  27% {
    margin-top: -465px;
  }
  34% {
    margin-top: -372px;
  }
  41% {
    margin-top: -372px;
  }
  48% {
    margin-top: -279px;
  }
  55% {
    margin-top: -279px;
  }
  62% {
    margin-top: -186px;
  }
  69% {
    margin-top: -186px;
  }
  76% {
    margin-top: -93px;
  }
  83% {
    margin-top: -93px;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

@media (max-width: 1023.9px) {
  @keyframes show {
    0% {
      margin-top: -342px;
    }

    17.6% {
      margin-top: -342px;
    }
    23.9% {
      margin-top: -342px;
    }
    29.5% {
      margin-top: -285px;
    }
    35.4% {
      margin-top: -285px;
    }
    41.3% {
      margin-top: -228px;
    }
    47.2% {
      margin-top: -228px;
    }
    53.1% {
      margin-top: -171px;
    }
    59% {
      margin-top: -171px;
    }
    65% {
      margin-top: -114px;
    }
    71% {
      margin-top: -114px;
    }
    76.8% {
      margin-top: -57px;
    }
    82.6% {
      margin-top: -57px;
    }
    90% {
      margin-top: 0px;
    }
    100% {
      margin-top: 0;
    }
  }
}
