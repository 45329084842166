.bottom-action {
  bottom: 0;
  box-shadow: 0px -14px 34px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  margin: auto;
}

/* tabs height for mobile & tablets  */
.tabs-height-leader {
  height: 100%;
}

/*using media query for tabs height for desktop  */

@media (min-width: 1024px) {
  .tabs-height-leader {
    height: calc(100vh - 231px);
  }
}
