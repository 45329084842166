.getStarted {
  background-image: url("../../../assets/images/mobile_bg.png");
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (min-width: 1024px) {
  .getStarted {
    background-image: url("../../../assets/images/desktop_bg.png");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 120%;
    background-repeat: no-repeat;
  }
}

.orlandomagic_bg {
  background-image: url("../../../assets/images/orlandomagic_mobile.png");
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .orlandomagic_bg {
    background-image: url("../../../assets/images/orlandomagic_desktop.png");
    height: 100%;
    width: 100%;
    background-position: bottom;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1024px) {
  .transition-linear-out {
    transition: visibility 0s linear 200ms, opacity 200ms;
  }

  .transition-linear-in {
    transition: visibility 0s linear 0s, opacity 200ms;
  }
}