.react-tel-input .form-control{
    height: 100%;
    background-color: transparent;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    padding-left: 54px;
    border: none;
    font-size: 20px;
    font-weight: 400;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus ,
.react-tel-input .flag-dropdown{
    background-color: transparent;
    border-radius: 10px !important;
    border: none;
}

.react-tel-input .selected-flag {
    width: 48px !important;
}
.react-tel-input .country-list{
    background-color: #333333;
    border-radius: 10px !important;
    border: 1px solid black;
    width: 318px;
    margin: 3px 0 0px 0px;
    max-height: 260px;
}
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .flag-dropdown.open{
    background: transparent;
    border-radius: 10px !important;

}
.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
    background-color: #000;
    
}
.react-tel-input .country-list .country{
    text-align: left;
}

.react-tel-input .flag .arrow{

    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    position: relative;
    top: 50%;
    margin-top: -5px;
    left: 22px;
    width: 17px;
    height: 17px;
    border: none;
  }
.react-tel-input .selected-flag .arrow.up {
    border: none;
}