/* tabs height for mobile & tablets  */
.mysocial-tabs-height {
  height: calc(100vh - 80px);
}

/*using media query for tabs height for mobile  */
@media (min-width: 1024px) {
  .mysocial-tabs-height {
    height: calc(100vh - 101px);
  }
}
